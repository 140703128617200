<template>
  <banner-modal v-if="isShowBannerModal"></banner-modal>
  <div v-show="!isShowBannerModal">
    <header-component></header-component>
    <div class="pt-16">
      <!--    <advertising-base>-->
      <!--      <router-view/>-->
      <!--    </advertising-base>-->
      <advertising-base-multi>
        <router-view/>
      </advertising-base-multi>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
// import AdvertisingBase from '@/components/AdvertisingBase.vue';
import AdvertisingBaseMulti from '@/components/AdvertisingBaseMulti.vue';
import BannerModal from '@/components/BannerModal.vue';
import { mapState } from 'vuex';

export default {
  name: 'AppComponent',
  components: {
    HeaderComponent,
    FooterComponent,
    // AdvertisingBase,
    AdvertisingBaseMulti,
    BannerModal
  },
  computed: {
    ...mapState([
      'isShowBannerModal'
    ])
  }
}
</script>

<style lang="scss">
  @use './style/settings';
</style>
