import { createStore } from 'vuex'

export default createStore({
  state: {
    isShowBannerModal: false,
  },
  getters: {
  },
  mutations: {
    showBannerModal (state) {
      state.isShowBannerModal = true
    },
    hideBannerModal (state) {
      state.isShowBannerModal = false
    }
  },
  actions: {
    showBannerModal ({ commit }) {
      commit('showBannerModal')
    },
    hideBannerModal ({ commit }) {
      commit('hideBannerModal')
    }
  },
  modules: {
  }
})
