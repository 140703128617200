<template>
  <div class="v-overlay align-center justify-center">
    <div class="v-overlay__scrim"></div>
    <div class="v-overlay__content">
      <v-container class="pa-0">
        <v-progress-circular
            v-if="loading"
            color="primary"
            size="64"
            indeterminate
        ></v-progress-circular>
        <template v-else>
          <div class="adv_block">
            <img v-if="banner" :src="banner['image']" :alt="banner['alt_name']" />
          </div>
          <div class="timer" v-if="timer > 0">{{ `00:${ timer }` }}</div>
        </template>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios';
import getPathRequest from '@/assets/helpers/get_path_request'
const startFrom = 30;

export default {
  name: 'BannerModal',
  data() {
    return {
      timer: -1,
      loading: false,
      banner: null
    }
  },
  created() {
    this.loading = true
    axios.get(getPathRequest('get_banner', {place: 'MODAL'}))
        .then(response => {
          this.banner = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false
        })
  },
  mounted() {
    this.setTimer()
  },
  methods: {
    ...mapActions([
      'hideBannerModal'
    ]),
    setTimer() {
      let time = startFrom;
      const updateInterval = () => {
        if(time < 10) {
          this.timer = '0' + time--;
        } else {
          this.timer = time--;
        }
        if(time < 0) {
          clearInterval(timer);
          this.hideBannerModal();
        }
      }
      updateInterval()
      const timer = setInterval(updateInterval, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.timer {
  position: absolute;
  right: 0;
  top: 100%;
}
</style>
