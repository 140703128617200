import serializeObjectToQuery from '@/assets/helpers/serialize_query_url';
/**
 * Функция получения адреса запроса по идентификатору
 * @param id Идентификатор адреса
 * @param payload Уникальные данные для генерации адреса (например, id сделки)
 * @returns {string}
 */

export default function getPathRequest(id, payload = {}) {
  const baseUrl = '';
  const urlList = {
    'get_banner': baseUrl + '/api/banner/' + payload['place'],
    'search_multi': baseUrl + '/api/data/search' + (payload.query ? '?' + serializeObjectToQuery(payload.query) : ''),
    'search_single': baseUrl + '/api/data/search' + (payload.query ? '?' + serializeObjectToQuery(payload.query) : '')
  };
  return urlList[id] ? urlList[id] : baseUrl;
}
