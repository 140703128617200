<template>
  <footer class="mt-3">
    <v-container>
      Copyright ©
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped lang="scss">
footer {
  background-color: #232e3c;
  color: #fff;
}
</style>
